.message {
  color: black;
}

.project-wrapper {
  margin-bottom: 3em;
}

.message:hover {
  color: rgb(133, 253, 183);
  background-color: transparent;
  text-decoration: none;
}

.project-pic {
  width: 100%;
  margin-bottom: 2em;
  box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
  -khtml-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.project-pic:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
  -khtml-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
}

.social-icon {
  width: 4vh;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 2em;
  margin-bottom: 25em;
  float: left;
}

.social-icon:hover {
  opacity: .5;
}

h1 {
  margin-top: 4em;
  margin-bottom: 1em;
}

h2 {
  margin-bottom: 1em;
}

h3 {
  margin-bottom: 1em;
}

h4 {
  margin-bottom: .5em;
}
nav.navbar, .navbar-default {
    background-color: white !important;
    border: 0;
    text-align: right;
    box-shadow: none;
  }

  .navbar-default {
    background-image: none;
    border: none;
    border-radius: 0;
  }

  .navbar-default .navbar-nav > li > a {
    color: black;
  }

  .navbar-default .navbar-brand {
    color: black;
  }
  
  .navbar-default .navbar-toggle {
    border-color: white;
  }
  
  .navbar-default .navbar-toggle .icon-bar {
    background-color: rgb(133, 253, 183);
  }
  
  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: transparent;
  }
  
  .navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
    color: rgb(133, 253, 183);
    background-color: transparent;
  }
  
  .navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
    color: rgb(133, 253, 183);
    background-color: transparent;
  }

  .hash-link {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }

  .hash-link:hover, .hash-link:active, .hash-link:focus {
    color: rgb(133, 253, 183);
    background-color: transparent;
    text-decoration: none;
  }
.screenshot {
    width: 50%;
    max-height: auto;
    border-radius: 0;
    margin-bottom: 2em;
    outline: none;
    box-shadow: 10px 10px 5px rgb(236, 236, 236);
	-moz-box-shadow: 10px 10px 5px rgb(236, 236, 236);
	-webkit-box-shadow: 10px 10px 5px rgb(236, 236, 236);
    -khtml-box-shadow: 10px 10px 5px rgb(236, 236, 236);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.screenshot:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);;
	-khtml-box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);
}

.screenshot:active, .screenshot:focus {
    outline: none;
}

.next-project-hub {
    width: 100%;
    min-width: 60px;
    box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
    -khtml-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.next-project-hub:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
    -khtml-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
}

h3 {
    font-weight: 700;
}

p {
    margin-bottom: 2em;
}
.screenshot {
    width: 100%;
    border-radius: 0;
    margin-bottom: 2em;
    outline: none;
    box-shadow: 10px 10px 5px rgb(236, 236, 236);
	-moz-box-shadow: 10px 10px 5px rgb(236, 236, 236);
	-webkit-box-shadow: 10px 10px 5px rgb(236, 236, 236);
    -khtml-box-shadow: 10px 10px 5px rgb(236, 236, 236);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.screenshot:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);;
	-khtml-box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);
}

.screenshot:active, .screenshot:focus {
    outline: none;
}

.next-project-hub {
    box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
    -khtml-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.next-project-hub:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
    -khtml-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
}

h4 {
    font-weight: 700;
}

p {
    margin-bottom: 2em;
}
.screenshot {
    width: 100%;
    border-radius: 0;
    margin-bottom: 2em;
    outline: none;
    box-shadow: 10px 10px 5px rgb(236, 236, 236);
	-moz-box-shadow: 10px 10px 5px rgb(236, 236, 236);
	-webkit-box-shadow: 10px 10px 5px rgb(236, 236, 236);
    -khtml-box-shadow: 10px 10px 5px rgb(236, 236, 236);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.screenshot:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);;
	-khtml-box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);
}

.screenshot:active, .screenshot:focus {
    outline: none;
}

.next-project-hub {
    width: 100%;
    min-width: 60px;
    box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
    -khtml-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.next-project-hub:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
    -khtml-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
}

h4 {
    font-weight: 700;
}

p {
    margin-bottom: 2em;
}
.screenshot {
    width: 100%;
    border-radius: 6px;
    margin-bottom: 2em;
    outline: none;
    box-shadow: 10px 10px 5px rgb(236, 236, 236);
	-moz-box-shadow: 10px 10px 5px rgb(236, 236, 236);
	-webkit-box-shadow: 10px 10px 5px rgb(236, 236, 236);
    -khtml-box-shadow: 10px 10px 5px rgb(236, 236, 236);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.screenshot:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);;
	-khtml-box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);
}

.screenshot:active, .screenshot:focus {
    outline: none;
}

.next-project {
    width: 90%;
    min-width: 60px;
    box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
    -khtml-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.next-project:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
    -khtml-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
}

h4 {
    font-weight: 700;
}

p {
    margin-bottom: 2em;
}
html, body {
    font-family: 'Montserrat', sans-serif;
    height: 100vh;
    margin: 0;
}

.heading {
    font-weight: 700;
}

.subheading {
    font-weight: 400;
}
