html, body {
    font-family: 'Montserrat', sans-serif;
    height: 100vh;
    margin: 0;
}

.heading {
    font-weight: 700;
}

.subheading {
    font-weight: 400;
}