.screenshot {
    width: 50%;
    max-height: auto;
    border-radius: 0;
    margin-bottom: 2em;
    outline: none;
    box-shadow: 10px 10px 5px rgb(236, 236, 236);
	-moz-box-shadow: 10px 10px 5px rgb(236, 236, 236);
	-webkit-box-shadow: 10px 10px 5px rgb(236, 236, 236);
    -khtml-box-shadow: 10px 10px 5px rgb(236, 236, 236);
    transition: all 0.5s ease;
}

.screenshot:hover {
    transform: scale(1.01);
    box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);;
	-khtml-box-shadow: 10px 10px 5px rgba(155, 153, 153, 0.747);
}

.screenshot:active, .screenshot:focus {
    outline: none;
}

.next-project-hub {
    width: 100%;
    min-width: 60px;
    box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
    -khtml-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
    transition: all 0.5s ease;
}

.next-project-hub:hover {
    transform: scale(1.05);
    box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
    -khtml-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
}

h3 {
    font-weight: 700;
}

p {
    margin-bottom: 2em;
}