nav.navbar, .navbar-default {
    background-color: white !important;
    border: 0;
    text-align: right;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .navbar-default {
    background-image: none;
    border: none;
    border-radius: 0;
  }

  .navbar-default .navbar-nav > li > a {
    color: black;
  }

  .navbar-default .navbar-brand {
    color: black;
  }
  
  .navbar-default .navbar-toggle {
    border-color: white;
  }
  
  .navbar-default .navbar-toggle .icon-bar {
    background-color: rgb(133, 253, 183);
  }
  
  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: transparent;
  }
  
  .navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
    color: rgb(133, 253, 183);
    background-color: transparent;
  }
  
  .navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
    color: rgb(133, 253, 183);
    background-color: transparent;
  }

  .hash-link {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }

  .hash-link:hover, .hash-link:active, .hash-link:focus {
    color: rgb(133, 253, 183);
    background-color: transparent;
    text-decoration: none;
  }