.message {
  color: black;
}

.project-wrapper {
  margin-bottom: 3em;
}

.message:hover {
  color: rgb(133, 253, 183);
  background-color: transparent;
  text-decoration: none;
}

.project-pic {
  width: 100%;
  margin-bottom: 2em;
  box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
  -khtml-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
  transition: all 0.5s ease;
}

.project-pic:hover {
  transform: scale(1.05);
  box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
	-moz-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
	-webkit-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);;
  -khtml-box-shadow: 5px 5px 5px rgba(155, 153, 153, 0.747);
}

.social-icon {
  width: 4vh;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 2em;
  margin-bottom: 25em;
  float: left;
}

.social-icon:hover {
  opacity: .5;
}

h1 {
  margin-top: 4em;
  margin-bottom: 1em;
}

h2 {
  margin-bottom: 1em;
}

h3 {
  margin-bottom: 1em;
}

h4 {
  margin-bottom: .5em;
}